<template>
  <v-col class="blockRowHolder" cols="12" :style="style">
    <h3 v-if="settings.showLabel" style="margin-bottom:.5em">{{ label }}</h3>
    <p v-if="info" v-html="info"></p>
    <div class="repeatable-block">
      <repeatable-row
        v-for="(row, entryIndex) in data"
        :key="'entry-' + entryIndex"
        :label="label"
        :inputs="inputs"
        :entry-index="entryIndex"
        :block-data="data"
        :row-data="row"
        :removable="settings.removable && data.length > settings.min"
        :unique-errors="uniqueErrors[entryIndex]"
        @update="updateEntry"
        @remove="removeEntry(entryIndex)">
      </repeatable-row>
    </div>
    <v-btn v-if="settings.addButton && (!('max' in settings) || settings.max == null || data.length < settings.max)" @click="addBlockEntry" class="add-entry" :color="errorMessage?'error':''">{{ settings.addButtonText || 'Add' }}</v-btn>
    <div v-if="errorMessage" class="error--text">{{ errorMessage }}</div>
  </v-col>
</template>
<style>
.repeatable-block div.repeatable-row:not(:last-child) {
  border-bottom: 1px solid #dcdcdc80;
  margin-bottom: 12px;
}
.blockRowHolder div.row div.col-12 {
  padding-bottom: 0;
}
.blockRowHolder button.v-btn.add-entry {
  transform: translateX(2px);
  margin-top: 1.5em;
}
</style>
<script>
import { ref, computed, watch, onBeforeMount } from '@vue/composition-api'

export default {
  components: {
    RepeatableRow: () => import('./repeatable/Row')
  },
  props: {
    input: {
      type: Object,
      required: true
    },
    formData: {
      type: Object,
      default: () => { return {} }
    }
  },
  setup (props, { emit, root }) {
    const data = ref([])

    const label = computed(() => props.input.label || '')
    const name = computed(() => props.input.name)
    const info = computed(() => props.input.info)
    const settings = computed(() => {
      return {
        showLabel: false,
        addButton: true,
        addButtonText: 'Add Another',
        min: 0,
        max: null,
        removable: true,
        ...props.input.settings
      }
    })
    const inputs = computed(() => props.input.inputs || [])
    const inputTypes = computed(() => {
      const obj = {}
      for (let i = 0; i < props.input.inputs.length; i++) {
        const { label, input } = props.input.inputs[i]
        obj[label] = input
      }
      return obj
    })
    const uniqueErrors = ref([])

    function addBlockEntry () {
      const dataTemp = {}
      const uniqueTemp = {}
      for (let i = 0; i < inputs.value.length; i++) {
        const { label, input, unique } = inputs.value[i]
        dataTemp[label] = (input === 'checkbox' || input === 'switch') ? false : ''
        if (unique) {
          uniqueTemp[label] = false
        }
      }
      data.value.push(dataTemp)
      uniqueErrors.value.push(uniqueTemp)
      if (errorMessage.value !== '' && data.value.length >= settings.value.min) {
        errorMessage.value = ''
      }
    }

    function updateEntry ({ index, field, value }) {
      data.value.splice(index, 1, { ...data.value[index], [field]: value })
      if (name.value) emit('update', { field: name.value, value: data.value[index], index })
      else emit('update', { field: label.value, value: data.value[index], index })

      checkUnique({ index, field, value })
    }

    function checkUnique ({ index, field, value }) {
      // Ensure if the given field has unique set
      let isUnique = false
      for (let i = 0; i < inputs.value.length; i++) {
        if (inputs.value[i].label === field) {
          isUnique = !!inputs.value[i].unique
          break
        }
      }
      // If unique is not set, then always just return; we don't need to validate for this to be a unique field
      if (!isUnique) return

      // Checkboxes and switches are enacted differently; if one is checked then it should uncheck all of the others
      // allowing only one checked entry for all of the inputs
      if (inputTypes.value[field] === 'checkbox' || inputTypes.value[field] === 'switch') {
        if (value) {
          for (let i = 0; i < data.value.length; i++) {
            if (i !== index && data.value[i][field]) {
              data.value.splice(i, 1, { ...data.value[i], [field]: false })
              emit('update', { field: label.value, value: data.value[i], index: i })
            }
          }
        }
      } else {
        // For all other input types, we will first build a hash with all of the unique values, with each one
        // containing an array of the indices that contain that match
        const matches = {}
        for (let i = 0; i < data.value.length; i++) {
          if (!(data.value[i][field] in matches)) matches[data.value[i][field]] = []
          matches[data.value[i][field]].push(i)
        }
        // console.log(matches)
        // Go through each unique value and see which ones have more than one entry matching the given value
        for (const matchVal in matches) {
          // console.log(matchVal, matches, matches[matchVal].length)
          if (matches[matchVal].length > 1) {
            // console.log('has an error')
            // Since there is more than one matching value, go through each matching value and add this field
            // to the uniqueErrors for the index (uniqueErrors is an array based on the index of the block in the
            // repeatable block; each entry has a list of the fields which must be unique, with each stating whether
            // it should show an error for not being unique (the value defaults to false but will be set to true
            // for this case where there are multiple matches)
            for (let i = 0; i < matches[matchVal].length; i++) {
              const index = matches[matchVal][i]
              uniqueErrors.value.splice(index, 1, { ...uniqueErrors.value[index], [field]: true })
            }
          } else {
            // console.log('has no error')
            // There is only one match, so make sure the associated value is "false" in the unique errors list
            const index = matches[matchVal][0]
            // console.log(index, uniqueErrors.value, uniqueErrors.value[index])
            if (uniqueErrors.value[index] && field in uniqueErrors.value[index]) {
              uniqueErrors.value.splice(index, 1, { ...uniqueErrors.value[index], [field]: false })
            }
          }
        }
      }
    }

    function removeEntry (entryIndex) {
      const temp = { ...data.value[entryIndex] }
      data.value.splice(entryIndex, 1)
      if (entryIndex in uniqueErrors.value) uniqueErrors.value.splice(entryIndex, 1)
      if (name.value) emit('update', { field: name.value, value: data.value, index: entryIndex, action: 'remove' })
      else emit('update', { field: label.value, value: data.value, index: entryIndex, action: 'remove' })
      for (const field in temp) {
        setTimeout(() => {
          checkUnique({ index: 999, field, value: temp[field] })
        }, 500)
      }
    }

    // Update the stored data to match what is in the props.formData, but only if there are actual changes.
    // We will be updating locally when we push changes, so in that case this will be the formData telling us that its data now matches what we sent
    watch(() => props.formData, () => {
      if (props.input.label in props.formData) {
        const blockValue = props.formData[props.input.label]
        // console.log(props.input.label)
        // console.log(blockValue)
        for (let i = 0; i < blockValue.length; i++) {
          if (!(i in data.value)) data.value.push(blockValue[i])
          else {
            if (JSON.stringify(blockValue[i]) !== JSON.stringify(data.value[i])) {
              data.value.splice(i, 1, blockValue[i])
            }
          }
        }
      }
    })

    // The goal is to have the inputArr include both the inputs and the data; this is similar to the inputArr of the Section component but is an array of arrays (base array is for each matching block of inputs)
    onBeforeMount(() => {
      // will I need to do anything here?
      if (settings.value.min > 0 && !settings.addButton) {
        for (let i = 0; i < settings.value.min; i++) {
          if (data.value.length <= i) addBlockEntry()
        }
      }
    })

    const errorMessage = ref('')
    function validate () {
      // if (!props.visible) return true
      if (data.value.length < settings.value.min) {
        errorMessage.value = 'Minimum of ' + settings.value.min + ' required'
        return false
      } else {
        for (let i = 0; i < uniqueErrors.value.length; i++) {
          for (const field in uniqueErrors.value[i]) {
            if (uniqueErrors.value[i][field]) {
              // console.log(uniqueErrors.value[i])
              return false
            }
          }
        }
        errorMessage.value = ''
        return true
      }
    }

    const style = computed(() => {
      // If the block requires one entry and does not have a button to add more, then remove all padding around it
      if (settings.value.min === 1 && !settings.value.addButton) return 'margin-bottom:10px'
      return 'margin-bottom:1em'
    })

    return {
      data,
      label,
      name,
      info,
      settings,
      inputs,
      inputTypes,
      uniqueErrors,
      addBlockEntry,
      updateEntry,
      removeEntry,
      errorMessage,
      validate,
      style
    }
  }
}
</script>
